/* body {
    font-family: "Lato", sans-serif;
  }
   */
/* Fixed sidenav, full height */
.sidenav {
  height: 100%;
  width: 250px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #fbfaff;
  overflow-x: hidden;
  padding-top: 20px;
  border-right: 1px solid #e9e9ef;
  font-weight: 500;
}

/* Style the sidenav links and the dropdown button */
.sidenav a,
.dropdown-btn {
  padding: 8px 8px 8px 16px;
  text-decoration: none;
  font-size: .9rem;
  color: #545a6d;
  display: block;
  border: none;
  background: none;
  width: 100%;
  text-align: left;
  cursor: pointer;
  outline: none;
  font-weight: 500;
}

/* On mouse-over */
.sidenav a:hover,
.dropdown-btn:hover {
  color: #5156be;
}

/* Main content */
.main {
  margin-left: 200px;
  /* Same as the width of the sidenav */
  font-size: 20px;
  /* Increased text to enable scrolling */
  padding: 0px 10px;
}

/* Add an active class to the active dropdown button */
.active-sidebar {
  background-color: green;
  color: white;
}

/* Dropdown container (hidden by default). Optional: add a lighter background color and some left padding to change the design of the dropdown content */
.dropdown-container {
  display: none;
  background-color: #fbfaff;
  padding-left: 8px;
}

.dropdown-container-show {
  display: block;
}

/* Optional: Style the caret down icon */
.fa-caret-down {
  float: right;
  padding-right: 8px;
}

.fa-angle-down {
  float: right;
  padding-right: 13px;
}

/* Some media queries for responsiveness */
@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }

  .sidenav a {
    font-size: .9rem;
  }
}