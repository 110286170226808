.MuiInputBase-input {
    border: 1px solid #cccccc !important;
    padding: 0.47rem 0.75rem !important;
    border-radius: 0.25rem;
    font-size: .875rem !important;
    color: #495057 !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    height: 22px !important;
    font-family: "IBM Plex Sans", sans-serif !important;
}

.MuiInput-underline:before {
    border-bottom: none !important;
}

.MuiInput-underline:after {
    border-bottom: none !important;
}

.MuiInputBase-input:hover {
    border-bottom: 1px solid #cccccc !important;
}

.css-1s2u09g-control:hover {
    border: 1px solid #cccccc !important;
}

.css-1pahdxg-control {
    border-color: #cccccc !important;
    box-shadow: none !important;
}

.css-1pahdxg-control:hover {
    border-color: #cccccc !important;
    box-shadow: none !important;
}

.css-1pahdxg-control:focus {
    border-color: #cccccc !important;
    box-shadow: none !important;
}

.currency-align {
    text-align: right;
}